<template>
  <div class="documents-page">
    <documents-list-block />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import DocumentsListBlock from "@/components/blocks/DocumentsListBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";

export default {
  name: "DocumentsPage",
  components: {
    DocumentsListBlock,
    CallbackBlock,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Проектная документация, разрешительные документы, заключения экспертиз и другая официальная документация по ЖК MainStreet",
      },
    ],
  },
};
</script>

<style lang="scss" scoped></style>
