var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "documents-list-block" },
    [
      _c("bread-crumbs"),
      _c(
        "content-wrapper-block",
        { attrs: { "no-overflow": "" } },
        [
          _c("bread-crumbs", { attrs: { dark: "" } }),
          _c("page-title", { attrs: { main: "" } }, [_vm._v(" Документы ")]),
          _c(
            "div",
            { staticClass: "documents-list-block__list" },
            [
              _vm._l(_vm.documents, function (item, index) {
                return _c("documents-item", {
                  key: index,
                  attrs: {
                    "file-name": item.data.title,
                    "file-date": item.data.date,
                    "file-u-r-l":
                      "https://api.gkosnova.tech/public/api/v1/download/" +
                      item.data.id,
                    target: "__blank",
                  },
                })
              }),
              _vm._v(" " + _vm._s(_vm.documents.original_name) + " "),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }