<template>
  <div class="documents-list-block">
    <bread-crumbs />
    <content-wrapper-block no-overflow>
      <bread-crumbs dark />
      <page-title main> Документы </page-title>
      <div class="documents-list-block__list">
        <documents-item
          v-for="(item, index) in documents"
          :key="index"
          :file-name="item.data.title"
          :file-date="item.data.date"
          :file-u-r-l="
            'https://api.gkosnova.tech/public/api/v1/download/' + item.data.id
          "
          target="__blank"
        />
        {{ documents.original_name }}
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import DocumentsItem from "@/components/elements/DocumentsItem";
import BreadCrumbs from "@/components/elements/BreadCrumbs";
import { mapState } from "vuex";

export default {
  name: "DocumentsListBlock",
  components: {
    BreadCrumbs,
    ContentWrapperBlock,
    PageTitle,
    DocumentsItem,
  },
  computed: {
    ...mapState({
      documents: (state) => state.documents,
    }),
  },
};
</script>

<style lang="scss" scoped>
.documents-list-block {
  background: #f4f4f4;
  @media screen and (min-width: $screen-lg) {
    .content-wrapper {
      padding-bottom: 90px;
    }
  }
  &__list {
    column-gap: 5%;
    column-count: 1;
    @media screen and (min-width: $screen-mini) {
      column-count: 2;
    }
    @media screen and (min-width: $screen-lg) {
      column-count: 3;
    }
    .documents-item {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.breadcrumbs {
  @media screen and (min-width: 414px) {
    margin-top: 0;
  }
}
</style>
